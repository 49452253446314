@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-primary text-white font-sans;
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23C2FF09%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.65em auto;
  padding-right: 1.5em;
  border: 1px solid #C2FF09; /* Default border color */
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

select:hover {
  border-color: #9D89FA; /* Change border color on hover */
}

select:focus {
  outline-color: #9D89FA; /* Change outline color on focus */
  border-color: #9D89FA; /* Change border color on focus */
}

select:valid {
  border-color: #9D89FA; /* Change border color after selection */
}

/* Ensure the select box is not valid until an option is selected */
select:invalid {
  border-color: #C2FF09; /* Keep the border color as #C2FF09 when no option is selected */
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.landing-page {
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.question-page {
  min-height: 100vh;
  overflow-y: auto;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #C2FF09;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}